<template>
    <div :class="['grid lg:grid-cols-3 min-h-screen', bgBody]">
       <div class="hidden lg:block" :style="{ backgroundImage: 'url('+ require(`../assets/images/${activeBg}.gif`) + ')', backgroundSize: 'cover', backgroundPosition: 'center' }">
           <p class="pl-8 pt-6"><svgicon name="bni" class="w-16 md:w-20 h-16 md:h-72"></svgicon></p>
       </div>
       <div class="lg:col-span-2">
            <div class="bg-white shadow-md p-4 lg:pr-12 flex justify-between lg:justify-end items-center">
               <div class="lg:hidden">
                   <img src="../assets/svg/bni.svg" class="w-9 h-8">
               </div>
               <div class="relative text-sm" >
                   <div class="flex items-center space-x-2 cursor-pointer" @click="drop = !drop">
                        <p class="bg-white">
                            <img v-if="user.profile" class="h-8 w-8 rounded-full" :src="fileUrl+`${user.profile.name}`" alt="">
                            <img v-else class="h-8 w-8 rounded-full" src="@/assets/svg/avatar.svg" alt="">
                        </p>
                        <p>{{user.lastname }} {{ user.firstname}}</p>
                        <p>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8751 9.00001L11.9951 12.88L8.1151 9.00001C7.92827 8.81275 7.67462 8.70752 7.4101 8.70752C7.14558 8.70752 6.89193 8.81275 6.7051 9.00001C6.3151 9.39001 6.3151 10.02 6.7051 10.41L11.2951 15C11.6851 15.39 12.3151 15.39 12.7051 15L17.2951 10.41C17.6851 10.02 17.6851 9.39001 17.2951 9.00001C16.9051 8.62001 16.2651 8.61001 15.8751 9.00001Z" fill="#09101D"/>
                            </svg>
                        </p>
                    </div>
                    <div v-if="drop" class="absolute w-full right-0 bg-white rouded-lg shadow-md p-6 flex flex-col space-y-2">
                        <router-link to="/dashboard/profil">
                            <p @click="drop = false">Profil</p>
                        </router-link>
                        <hr class="bg-borderColor">
                        <p class="cursor-pointer" @click="logout">Déconnexion</p>
                    </div>
               </div>
            </div>

           <div class="customH overflow-auto scrollbar scrollbar-thumb-bordercol scrollbar-track-gray-10">
               <router-view @emitBg="bgBody = $event"></router-view>
           </div>
       </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fileUrl: process.env.VUE_APP_BACKEND_URL+'files/download/',
            drop: false,
            carIncrement: 1,
            activeBg: 'cofeeMan',
            carousselImages: ['cofeeWoman', 'cofeeMan'],
            bgBody: 'bg-white'
        }
    },
    watch: {
        /* activeBg: {
            immediate: true,
            handler(){
                if(this.carIncrement < this.carousselImages.length) this.carIncrement++
                else {this.carIncrement=1}
                const self = this;
                setTimeout(function () {
                    self.activeBg = self.carousselImages[self.carIncrement-1]
                }, 10000);
            }
        } */
    },
    components: {

    },
    computed: {
        user(){
            return this.$store.getters.getUser
        }
    },
    methods: {
        logout(){
            this.drop = false
            localStorage.removeItem('token')
            this.$router.push('/')
        }
    },
}
</script>

<style scoped>
.customH{
    min-height: calc(100vh - 64px);
}
.customH{
    max-height: calc(100vh - 64px);
}
</style>